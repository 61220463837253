import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { openSnackbar } from '../Notifier';
import {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_API_BASE_URL,
} from '../../env';
import { cloneDeep } from 'lodash';

import axios from 'axios';

const CancelToken = axios.CancelToken;

const config = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
};

const API_BASE_URL = REACT_APP_API_BASE_URL;

class Firebase {
  constructor() {
    this.app = initializeApp(config);
    // this.auth = this.app.auth();
    this.auth = getAuth(this.app);
    this.userLoaded = false;
    this.countriesLoaded = false;
    this.loadAllCountriesIntoCache();
    // this.loadAllCountriesIntoCache2();
  }

  loadAllCountriesIntoCache = () => {
    this.countryPromise = this.getAllRecursive(
      'locations',
      {},
      { countriesOnly: true, itemsPerPage: 200, currentPage: 1 }
    )
      .then((countries) => {
        this.countriesLoaded = true;
        this.allCountriesCached = countries;
      })
      .catch((err) => {
        // must catch this error for the case when accessing a public route and this method fails as no logged in user
        console.log('err: ', err);
      });
  };

  getTradeWindowLocations = () => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/tw_locations`,
    });
  };

  getCachedCountries = (textQuery = '') => {
    textQuery = textQuery.toLowerCase();
    return new Promise((resolve, reject) => {
      const filteredCountries = (textQuery) => {
        return this.allCountriesCached.filter((c) => {
          return `${c.code} ${c.name}`.toLowerCase().includes(textQuery);
        });
      };
      if (this.countriesLoaded) {
        resolve(filteredCountries(textQuery));
      } else {
        this.countryPromise.then(() => {
          resolve(filteredCountries(textQuery));
        });
      }
    });
  };

  getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      if (this.userLoaded) {
        resolve(this.auth.currentUser);
      }
      const unsubscribe = this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.userLoaded = true;
          resolve(user);
        } else {
          reject();
        }
        unsubscribe();
      }, reject);
    });
  };

  protectedRoute = async (options, source = undefined) => {
    const currentUser = await this.getCurrentUser();
    return (
      currentUser
        .getIdToken()
        .then((idToken) => {
          // if there is data passed, and at least one of the data values is a file
          // append all data as form data
          if (
            options.data &&
            Object.values(options.data)
              .map((v) => {
                return v instanceof File || (Array.isArray(v) && v.some((v2) => v2 instanceof File));
              })
              .find((v) => v === true)
          ) {
            const formData = new FormData();
            Object.entries(options.data).forEach(([k, v]) => {
              if (v instanceof File) {
                formData.append(k, v);
              } else if (Array.isArray(v) && v.some((v2) => v2 instanceof File)) {
                Object.values(v).forEach((v2) => {
                  formData.append(k, v2);
                });
              } else {
                formData.append(k, JSON.stringify(v));
              }
            });
            options.data = formData;
          }
          return axios({
            ...options,
            cancelToken: source ? source.token : undefined,
            headers: {
              'x-access-token': idToken,
              Pragma: 'no-cache',
            },
            responseType: 'json',
            withCredentials: true,
          });
        })
        .then((response) => response.data)
        // .catch((error) => this.handleError(error))
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            this.handleError(error);
          }
        })
    );
  };

  handleError = (error) => {
    let errorText;
    console.log('error: ', error.response);
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        errorText = error.response.data.message;
      } else {
        errorText = error.response.data;
      }
    } else {
      errorText = JSON.stringify(error);
    }
    openSnackbar({
      message: `Error Loading data: ${errorText}`,
      variant: 'error',
    });
    throw error;
  };

  publicRoute = async (options, source = undefined) => {
    return (
      axios({
        ...options,
        cancelToken: source ? source.token : undefined,
        responseType: 'json',
      })
        .then((response) => response.data)
        // .catch((error) => this.handleError(error));
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            this.handleError(error);
          }
        })
    );
  };

  createSource = () => {
    // cancel the request (the message parameter is optional)
    // source.cancel('Operation canceled by the user.');
    return CancelToken.source();
  };

  getAllRecursive = (methodName, where = {}, options = { itemsPerPage: 20, currentPage: 1 }, accumulator = []) => {
    if (methodName === 'clients') {
      throw 'Do not call with clients as there can be many thousands of clients for a give freight forwarder';
    }
    return this[methodName](where, options).then((response) => {
      const { totalItems } = response;
      const { itemsPerPage, currentPage } = options;
      accumulator.push(...response[methodName]);
      if (itemsPerPage * currentPage < totalItems) {
        return this.getAllRecursive(methodName, where, { ...options, currentPage: currentPage + 1 }, accumulator);
      } else {
        return Promise.resolve(accumulator);
      }
    });
  };

  // **************************** Auth API ****************************

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        if (this.userRequest === undefined) {
          // there is no currently waiting userRequest so send an new one
          // Note: if userRequest exists it will not send another request, just wait for the first one to finish
          this.userRequest = this.user(authUser.uid);
        }

        this.userRequest
          .then((dbUser) => {
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };
            next(authUser);
          })
          .finally(() => {
            if (this.userRequest) {
              // remove the userRequest as we have finished now
              this.userRequest = undefined;
            }
          });
      } else {
        fallback();
      }
    });

  doSignOut = () => this.auth.signOut();

  // **************************** Admin API ****************************

  notifyOrchidAdminNewUser = (email) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/admin/notify-new-user`,
      data: { email },
    });
  };

  // **************************** User API ****************************

  user = (id, useUid = true, params = { includeConstants: true }) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/users/${id}`,
      params: {
        useUid,
        ...params,
      },
    });
  };

  userUpdate = (user) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/users/${user._id}`,
      data: user,
    }).then(() => {
      openSnackbar({ message: 'User Updated', variant: 'success' });
    });
  };

  userUpdateSetting = (user_id, setting) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/users/${user_id}/settings`,
      data: setting,
    });
  };

  userCreate = (user) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/users`,
      data: user,
    }).then(() => {
      openSnackbar({ message: 'User Created', variant: 'success' });
    });
  };

  userDelete = (user) => {
    return this.protectedRoute({
      method: 'delete',
      url: `${API_BASE_URL}/users/${user._id}`,
    }).then(() => {
      openSnackbar({ message: 'User Deleted', variant: 'success' });
    });
  };

  users = (where = {}, options = { itemsPerPage: 20, currentPage: 1, includeCounts: false }) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/users`,
      params: {
        where,
        ...options,
      },
    });
  };

  // **************************** Locations API ****************************

  locations = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }, source) => {
    return this.protectedRoute(
      {
        method: 'get',
        url: `${API_BASE_URL}/locations`,
        params: {
          where,
          ...options,
        },
      },
      source
    );
  };

  // **************************** Containers API ****************************

  containers = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/containers`,
      params: {
        where,
        ...options,
      },
    });
  };

  // **************************** FCLServices API ****************************

  fclService = (freight_forwarder_id, id, params = {}) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/fcl_services/${id}`,
      params: params,
    });
  };

  fclServiceUpdate = (service) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/freight-forwarders/${service.freight_forwarder_id}/fcl_services/${service._id}`,
      data: service,
    }).then(() => {
      openSnackbar({ message: 'Port Pair Updated', variant: 'success' });
    });
  };

  fclServiceClone = (service) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${service.freight_forwarder_id}/fcl_services`,
      params: {
        clone_from_id: service._id,
      },
    }).then((returnedService) => {
      openSnackbar({ message: 'Port Pair Cloned', variant: 'success' });
      return returnedService;
    });
  };

  fclServiceCreate = (service) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${service.freight_forwarder_id}/fcl_services`,
      data: service,
    }).then(() => {
      openSnackbar({ message: 'Port Pair Created', variant: 'success' });
    });
  };
  fclServiceDelete = (service) => {
    return this.protectedRoute({
      method: 'delete',
      url: `${API_BASE_URL}/freight-forwarders/${service.freight_forwarder_id}/fcl_services/${service._id}`,
    }).then(() => {
      openSnackbar({ message: 'Port Pair Deleted', variant: 'success' });
    });
  };

  fclServices = (freight_forwarder_id, where = {}, sort = {}, options = {}) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/fcl_services`,
      params: {
        populateLocations: true,
        where: where,
        sort: sort,
        ...options,
      },
    });
  };

  fclServicesDownloadTemplate = (freight_forwarder_id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/fcl_services/template`,
    });
  };

  fclServicesSpreadsheet = (freight_forwarder_id, params = {}) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/fcl_services/spreadsheet`,
      params: params,
    });
  };

  fclServicesUploadSpreadsheet = (freight_forwarder_id, data, params = {}) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/fcl_services/spreadsheet`,
      data: data,
      params: params,
    }).then((response) => {
      this.processUploadCsvResponse(response, 'Port Pairs');
      if (response.error) {
        throw response.error;
      }
    });
  };

  fclServicesUploadCsv = (freight_forwarder_id, data) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/fcl_services/template`,
      data: data,
    }).then((response) => {
      this.processUploadCsvResponse(response, 'Port Pairs');
    });
  };

  processUploadCsvResponse = (response, objectType) => {
    const { parsedRowCount, insertedCount, errorRowNumbers, error } = response;
    let message = `Read ${parsedRowCount} rows. \nInserted ${insertedCount} ${objectType}`;
    let variant = 'success';
    if (errorRowNumbers && errorRowNumbers.length > 0) {
      variant = 'error';
      errorRowNumbers.forEach((row) => {
        message += `\nRow number ${row} was not valid, so was not processed`;
      });
      if (error) {
        message += `\n At least one row had this error: ${error}`;
      }
    }
    openSnackbar({ message: message, variant: variant, autoHideDuration: null });
  };

  fclServiceBulkUpdate = (freight_forwarder_id, serviceObject, filter) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/fcl_services/bulk_update`,
      data: { serviceObject, filter },
    }).then((response) => {
      openSnackbar({
        message: `Bulk Update Applied to ${response.updatedCount} records`,
        variant: 'success',
      });
    });
  };

  // **************************** charges API ****************************

  charges = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }) => {
    const { freight_forwarder_id } = options;
    if (freight_forwarder_id) {
      return this.protectedRoute({
        method: 'get',
        url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/charges`,
        params: {
          where,
          ...options,
        },
      });
    } else {
      return Promise.reject('freight_forwarder_id required');
    }
  };

  charge = (freight_forwarder_id, id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/charges/${id}`,
    });
  };

  chargeCodes = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }) => {
    const { freight_forwarder_id } = where;
    if (freight_forwarder_id) {
      return this.protectedRoute({
        method: 'get',
        url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/charges/codes`,
        params: {
          where,
          ...options,
        },
      });
    } else {
      return Promise.reject('freight_forwarder_id required');
    }
  };

  chargeCreate = (charge) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${charge.freight_forwarder_id}/charges`,
      data: charge,
    }).then((response) => {
      openSnackbar({ message: 'Charge Created', variant: 'success' });
      return response;
    });
  };
  chargeUpdate = (charge) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/freight-forwarders/${charge.freight_forwarder_id}/charges/${charge._id}`,
      data: charge,
    }).then(() => {
      openSnackbar({ message: 'Charge Updated', variant: 'success' });
    });
  };
  chargeDelete = (charge) => {
    return this.protectedRoute({
      method: 'delete',
      url: `${API_BASE_URL}/freight-forwarders/${charge.freight_forwarder_id}/charges/${charge._id}`,
    }).then(() => {
      openSnackbar({ message: 'Charge Deleted', variant: 'success' });
    });
  };

  chargesDownloadTemplate = (freight_forwarder_id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/charge-bulk-updates`,
    });
  };

  chargesUploadCsv = (freight_forwarder_id, data) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/charge-bulk-updates`,
      data: data,
    }).then((response) => {
      this.processUploadCsvResponse(response, 'Charges');
    });
  };

  // **************************** Incoterms API ****************************

  incoterms = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/incoterms`,
      params: {
        where,
        ...options,
      },
    });
  };

  // **************************** Currency API ****************************

  currencyUpdate = (freight_forwarder_id, currency) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/currencies/${currency._id}`,
      data: currency,
    }).then((response) => {
      openSnackbar({ message: 'Currency Updated', variant: 'success' });
      return response;
    });
  };

  currencyCreate = (freight_forwarder_id, currency) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/currencies`,
      data: currency,
    }).then((response) => {
      openSnackbar({ message: 'Currency Created', variant: 'success' });
      return response;
    });
  };

  currencyDelete = (freight_forwarder_id, currency) => {
    return this.protectedRoute({
      method: 'delete',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/currencies/${currency._id}`,
    }).then((response) => {
      openSnackbar({ message: 'Currency Deleted', variant: 'success' });
      return response;
    });
  };

  // **************************** Client API ****************************

  client = (freight_forwarder_id, client_id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/clients/${client_id}`,
    });
  };

  clientUpdate = (client) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/freight-forwarders/${client.freight_forwarder_id}/clients/${client._id}`,
      data: client,
    }).then((response) => {
      openSnackbar({ message: 'Client Updated', variant: 'success' });
      return response;
    });
  };

  clientCreate = (client) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${client.freight_forwarder_id}/clients`,
      data: client,
    }).then((response) => {
      openSnackbar({ message: 'Client Created', variant: 'success' });
      return response;
    });
  };

  clientDelete = (client) => {
    return this.protectedRoute({
      method: 'delete',
      url: `${API_BASE_URL}/freight-forwarders/${client.freight_forwarder_id}/clients/${client._id}`,
    }).then(() => {
      openSnackbar({ message: 'Client Deleted', variant: 'success' });
    });
  };

  clients = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }) => {
    const { freight_forwarder_id } = where;
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/clients`,
      params: {
        where,
        ...options,
      },
    });
  };

  clientsDownloadTemplate = (freight_forwarder_id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/client-bulk-updates`,
    });
  };

  clientsUploadCsv = (freight_forwarder_id, data) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/client-bulk-updates`,
      data: data,
    }).then((response) => {
      this.processUploadCsvResponse(response, 'Clients');
    });
  };

  // **************************** Service Provider API ****************************

  serviceProvider = (freight_forwarder_id, service_provider_id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/service-providers/${service_provider_id}`,
    });
  };

  serviceProviderUpdate = (service_provider) => {
    return this.protectedRoute({
      method: 'put',
      url:
        `${API_BASE_URL}/freight-forwarders/${service_provider.freight_forwarder_id}` +
        `/service-providers/${service_provider._id}`,
      data: service_provider,
    }).then((response) => {
      openSnackbar({
        message: 'Service Provider Updated',
        variant: 'success',
      });
      return response;
    });
  };

  serviceProviderCreate = (service_provider) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${service_provider.freight_forwarder_id}/service-providers`,
      data: service_provider,
    }).then((response) => {
      openSnackbar({
        message: 'Service Provider Created',
        variant: 'success',
      });
      return response;
    });
  };

  serviceProviderDelete = (service_provider) => {
    return this.protectedRoute({
      method: 'delete',
      url:
        `${API_BASE_URL}/freight-forwarders/${service_provider.freight_forwarder_id}` +
        `/service-providers/${service_provider._id}`,
    }).then(() => {
      openSnackbar({
        message: 'Service Provider Deleted',
        variant: 'success',
      });
    });
  };

  serviceProviders = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }) => {
    const { freight_forwarder_id } = where;
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/service-providers`,
      params: {
        where,
        ...options,
      },
    });
  };

  // **************************** Serviced Update API ****************************

  servicedUpload = (freight_forwarder_id, serviced_upload_id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/serviced-uploads/${serviced_upload_id}`,
    });
  };

  servicedUploadUpdate = (serviced_upload) => {
    return this.protectedRoute({
      method: 'put',
      url:
        `${API_BASE_URL}/freight-forwarders/${serviced_upload.freight_forwarder_id}` +
        `/serviced-uploads/${serviced_upload._id}`,
      data: serviced_upload,
    }).then((response) => {
      openSnackbar({
        message: 'Serviced Upload Updated',
        variant: 'success',
      });
      return response;
    });
  };

  servicedUploadCreate = (serviced_upload) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${serviced_upload.freight_forwarder_id}/serviced-uploads`,
      data: serviced_upload,
    }).then((response) => {
      openSnackbar({
        message: 'Serviced Upload Created',
        variant: 'success',
      });
      return response;
    });
  };

  servicedUploadDelete = (serviced_upload) => {
    return this.protectedRoute({
      method: 'delete',
      url:
        `${API_BASE_URL}/freight-forwarders/${serviced_upload.freight_forwarder_id}` +
        `/serviced-uploads/${serviced_upload._id}`,
    }).then(() => {
      openSnackbar({
        message: 'Serviced Upload Deleted',
        variant: 'success',
      });
    });
  };

  servicedUploads = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }) => {
    const { freight_forwarder_id } = where;
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/serviced-uploads`,
      params: {
        where,
        ...options,
      },
    });
  };

  // **************************** Agent API ****************************

  agent = (freight_forwarder_id, agent_id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/agents/${agent_id}`,
    });
  };

  agentUpdate = (agent) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/freight-forwarders/${agent.freight_forwarder_id}/agents/${agent._id}`,
      data: agent,
    }).then((response) => {
      openSnackbar({ message: 'Agent Updated', variant: 'success' });
      return response;
    });
  };

  agentCreate = (agent) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${agent.freight_forwarder_id}/agents`,
      data: agent,
    }).then((response) => {
      openSnackbar({ message: 'Agent Created', variant: 'success' });
      return response;
    });
  };

  agentDelete = (agent) => {
    return this.protectedRoute({
      method: 'delete',
      url: `${API_BASE_URL}/freight-forwarders/${agent.freight_forwarder_id}/agents/${agent._id}`,
    }).then(() => {
      openSnackbar({ message: 'Agent Deleted', variant: 'success' });
    });
  };

  agents = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }) => {
    const { freight_forwarder_id } = where;
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/agents`,
      params: {
        where,
        ...options,
      },
    });
  };

  // **************************** Freight Forwarders API ****************************

  freightForwarder = (id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${id}`,
    });
  };

  freightForwarderApiKey = (id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${id}/api-key`,
    });
  };

  freightForwarders = (where = {}, options = { itemsPerPage: 20, currentPage: 1 }) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders`,
      params: {
        where,
        ...options,
      },
    });
  };

  freightForwarderUpdate = (freightForwarder) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/freight-forwarders/${freightForwarder._id}`,
      data: freightForwarder,
    }).then((updatedFreightForwarder) => {
      openSnackbar({
        message: 'Freight Forwarder Updated',
        variant: 'success',
      });
      return updatedFreightForwarder;
    });
  };

  freightForwarderCreate = (freightForwarder) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders`,
      data: freightForwarder,
    }).then(() => {
      openSnackbar({
        message: 'Freight Forwarder Created',
        variant: 'success',
      });
    });
  };

  freightForwarderDelete = (freightForwarder) => {
    return this.protectedRoute({
      method: 'delete',
      url: `${API_BASE_URL}/freight-forwarders/${freightForwarder._id}`,
    }).then(() => {
      openSnackbar({
        message: 'Freight Forwarder Deleted',
        variant: 'success',
      });
    });
  };

  // **************************** RateSheets API ****************************

  rateSheetCreate = (ratesheet) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${ratesheet.freight_forwarder_id}/ratesheets`,
      data: ratesheet,
    }).then((returnedRateSheet) => {
      openSnackbar({ message: 'RateSheet Created', variant: 'success' });
      return returnedRateSheet;
    });
  };

  rateSheets = (freight_forwarder_id, where = {}, options = { itemsPerPage: 20, currentPage: 1 }, sort = {}) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/ratesheets`,
      params: {
        where,
        ...options,
        sort,
      },
    });
  };

  rateSheet = (freight_forwarder_id, id, options = {}) => {
    return this.publicRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/ratesheets/${id}`,
      params: options,
    });
  };

  rateSheetNumber = (freight_forwarder_id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/rateSheetNumber`,
    });
  };

  rateSheetUpdate = (ratesheet) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/freight-forwarders/${ratesheet.freight_forwarder_id}/ratesheets/${ratesheet._id}`,
      data: ratesheet,
    }).then((returnedRatesheet) => {
      openSnackbar({ message: 'Ratesheet Updated', variant: 'success' });
      return returnedRatesheet;
    });
  };

  rateSheetClone = (ratesheet) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${ratesheet.freight_forwarder_id}/ratesheets`,
      params: {
        clone_from_id: ratesheet._id,
      },
    }).then((returnedRatesheet) => {
      openSnackbar({ message: 'Rate Sheet Cloned', variant: 'success' });
      return returnedRatesheet;
    });
  };

  rateSheetCreateEmail = (ratesheet, message) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${ratesheet.freight_forwarder_id}/ratesheets/${ratesheet._id}/email`,
      data: message,
    }).then((returnedRateSheet) => {
      openSnackbar({ message: 'Email Sent', variant: 'success' });
      return returnedRateSheet;
    });
  };

  addRateSheetDocument = (ratesheet, documents) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${ratesheet.freight_forwarder_id}/ratesheets/${ratesheet._id}/document`,
      data: { documents },
    }).then((returnedRateSheet) => {
      openSnackbar({ message: 'Document Uploaded', variant: 'success' });
      return returnedRateSheet;
    });
  };

  getRateSheetDocuments = (ratesheet) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${ratesheet.freight_forwarder_id}/ratesheets/${ratesheet._id}/document`,
    }).then((returnedRateSheet) => {
      return returnedRateSheet;
    });
  };

  rateSheetDocumentDelete = (ratesheet, document) => {
    return this.protectedRoute({
      method: 'delete',
      url:
        `${API_BASE_URL}/freight-forwarders/${ratesheet.freight_forwarder_id}` +
        `/ratesheets/${ratesheet._id}/document/${document._id}`,
    }).then((returnedRateSheet) => {
      openSnackbar({ message: 'Document Deleted', variant: 'success' });
      return returnedRateSheet;
    });
  };

  // **************************** Quotes API ****************************

  quotes = (freight_forwarder_id, where = {}, options = { itemsPerPage: 20, currentPage: 1 }, sort = {}) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/quotes`,
      params: {
        where,
        ...options,
        sort,
      },
    });
  };

  quote = (freight_forwarder_id, id, options = {}) => {
    return this.publicRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/quotes/${id}`,
      params: options,
    });
  };

  quoteNumber = (freight_forwarder_id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/quoteNumber`,
    });
  };

  quoteHistory = (freight_forwarder_id, id) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${freight_forwarder_id}/quotes/${id}/history`,
    });
  };

  quoteCreate = (quote, params = {}) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes`,
      data: quote,
      params: params,
    }).then((returnedQuote) => {
      openSnackbar({ message: 'Quote Created', variant: 'success' });
      return returnedQuote;
    });
  };

  quoteClone = (quote) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes`,
      params: {
        clone_from_id: quote._id,
      },
    }).then((returnedQuote) => {
      openSnackbar({ message: 'Quote Cloned', variant: 'success' });
      return returnedQuote;
    });
  };

  quoteUpdate = (quote) => {
    return this.protectedRoute({
      method: 'put',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}`,
      data: quote,
    }).then((returnedQuote) => {
      openSnackbar({ message: 'Quote Updated', variant: 'success' });
      return returnedQuote;
    });
  };

  quoteBook = (quote) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}/book`,
      data: quote,
    }).then((returnedQuote) => {
      return returnedQuote;
    });
  };

  quoteCreateEmail = (quote, message) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}/email`,
      data: message,
    }).then((returnedQuote) => {
      openSnackbar({ message: 'Email Sent', variant: 'success' });
      return returnedQuote;
    });
  };

  quoteDelete = (quote) => {
    return this.protectedRoute({
      method: 'delete',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}`,
    }).then(() => {
      openSnackbar({ message: 'Quote Deleted', variant: 'success' });
    });
  };

  quoteToXero = (quote, contactID) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/xero`,
      data: { ...quote, contactID },
    }).then((response) => {
      const { consentUrl } = response;
      if (consentUrl) {
        // we have not yet connected to Xero for this session, so trigger the auth flow
        const link = document.createElement('a');
        link.href = consentUrl;
        link.setAttribute('target', '_blank');
        link.click();
      } else {
        openSnackbar({
          message: 'Quote Sent to Xero',
          variant: 'success',
        });
      }
      return response;
    });
  };

  quoteXeroContacts = (quote) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/xero/contacts`,
      data: quote,
    });
  };

  prepareQuoteForCyberFreight = (quote) => {
    const clonedQuote = cloneDeep(quote);
    if (clonedQuote.charges && clonedQuote.charges.length > 0) {
      for (const charge of clonedQuote.charges) {
        if (charge.buyBaseFee === 0) {
          delete charge.buyBaseFee;
        }
        if (charge.buyMinimum === 0) {
          delete charge.buyMinimum;
        }
        if (charge.sellBaseFee === 0) {
          delete charge.sellBaseFee;
        }
        if (charge.sellMinimum === 0) {
          delete charge.sellMinimum;
        }
      }
    }
    return clonedQuote;
  };

  quoteToCargoWise = (quote) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}/cargowise`,
      data: quote,
    }).then((response) => {
      openSnackbar({
        message: 'Quote Sent to CargoWise',
        variant: 'success',
      });
      return response;
    });
  };

  quoteToCyberFreight = (quote) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}/cyberfreight`,
      data: this.prepareQuoteForCyberFreight(quote),
    }).then((response) => {
      openSnackbar({
        message: 'Quote Sent to TradeWindow Freight',
        variant: 'success',
      });
      return response;
    });
  };

  quoteToCyberFreightJson = (quote) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}/cyberfreightjson`,
      data: this.prepareQuoteForCyberFreight(quote),
    }).then((response) => {
      openSnackbar({
        message: 'TradeWindow Freight JSON returned',
        variant: 'success',
      });
      return response;
    });
  };

  downloadQuotePdf = (quote_id) => {
    // // make an HTTP POST request to the cloud functions
    const url = 'https://us-central1-freight-legend.cloudfunctions.net/retrieveQuote';
    // const axios = require('axios');

    // Given that with axios we cannot use the usual 'Content-Disposition' trick to download a file,
    // we have to go about this the oldschool JS way, using clickable links and blob response types.
    return this.auth.currentUser
      .getIdToken()
      .then((token) => {
        const request = {
          auth_token: token,
          quote_id: quote_id,
        };
        return axios({
          method: 'post',
          url: url,
          data: request,
          responseType: 'blob',
        });
      })
      .then((result) => {
        console.log('Quote', result);
        const pdfStream = [result.data];
        const filename = `quote-${quote_id}.pdf`;
        const url = window.URL.createObjectURL(new Blob(pdfStream));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        return result;
      })
      .catch((error) => {
        console.error('Something went wrong', error);
      });
  };

  addDocument = (quote, documents) => {
    return this.protectedRoute({
      method: 'post',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}/document`,
      data: { documents },
    }).then((returnedQuote) => {
      openSnackbar({ message: 'Document Uploaded', variant: 'success' });
      return returnedQuote;
    });
  };

  getDocuments = (quote) => {
    return this.protectedRoute({
      method: 'get',
      url: `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}/document`,
    }).then((returnedQuote) => {
      return returnedQuote;
    });
  };

  documentDelete = (quote, document) => {
    return this.protectedRoute({
      method: 'delete',
      url:
        `${API_BASE_URL}/freight-forwarders/${quote.freight_forwarder_id}/quotes/${quote._id}` +
        `/document/${document._id}`,
    }).then((returnedDoc) => {
      openSnackbar({ message: 'Document Deleted', variant: 'success' });
      return returnedDoc;
    });
  };
}

export default Firebase;
