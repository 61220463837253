import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';


let openSnackbarFn;

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-line',
    },
});

const MySnackbarContent = forwardRef((props, ref) => {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
  
    return (
        <SnackbarContent
            ref={ref}
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
})

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};
  
const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);
  
class Notifier extends React.Component {
    state = {
        open: false,
        message: '',
        autoHideDuration: 3000
    };

    componentDidMount() {
        openSnackbarFn = this.openSnackbar;
    }

    openSnackbar = ( options ) => {
        if (options.variant === "error" && options.autoHideDuration === undefined) {
            options.autoHideDuration = null
        }
        this.setState({
            open: true,
            ...options,
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            open: false,
            message: '',
        });
    };

    render() {
        const { message, variant, autoHideDuration } = this.state;
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.open}
                autoHideDuration={autoHideDuration}
                onClose={this.handleSnackbarClose}
            >
                <MySnackbarContentWrapper
                    onClose={this.handleSnackbarClose}
                    variant={variant}
                    message={message}
                />
            </Snackbar>
        );
    }
}

export function openSnackbar(options) {
    openSnackbarFn(options);
}

export default Notifier;