import 'react-app-polyfill/ie11'; //for IE11 support
// import 'core-js/es7/array'; //for IE11 support
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';

// import App from './pages/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// inspiration for muliple apps take fron here: https://stackoverflow.com/questions/31933359/using-react-in-a-multi-page-app?rq=1

const apps = {
    App: () => import('./pages/App') // Main App
    // 'Two': () => import('./Two'),
};

const renderAppInElement = el => {
    if (apps[el.id]) {
        apps[el.id]().then(App => {
            let ImportedApp = App.default; // Access default export
            return ReactDOM.render(
                <FirebaseContext.Provider value={new Firebase()}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <ImportedApp />
                    </MuiPickersUtilsProvider>
                </FirebaseContext.Provider>,
                el
            );
        });
    }
};
// <App {...el.dataset} />,

Array.prototype.slice
    .call(document.querySelectorAll('.__react-root'), 0)
    .forEach(renderAppInElement);

// ReactDOM.render(
//     <FirebaseContext.Provider value={new Firebase()}>
//         <MuiPickersUtilsProvider utils={MomentUtils}>
//             <App />
//         </MuiPickersUtilsProvider>
//     </FirebaseContext.Provider>,
//     document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
