// See this post to undestand why this is done:
// https://stackoverflow.com/questions/45064842/heroku-pipeline-staging-env-variable-carried-into-production
export const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY;
export const REACT_APP_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const REACT_APP_DATABASE_URL = process.env.REACT_APP_DATABASE_URL;
export const REACT_APP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const REACT_APP_STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const REACT_APP_MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const REACT_APP_BASE_URL = `${process.env.REACT_APP_BASE_URL}${REACT_APP_BASE_PATH}`;
export const REACT_APP_TRADE_WINDOW_TERMINAL_URL = process.env.REACT_APP_TRADE_WINDOW_TERMINAL_URL;
